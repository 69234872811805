.form{
    width: 402px;    
}

.geographic-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px;

    .input-field {
        width: 240px !important;
    }
}

.btn-add-section {
    width: 180px;
    height: 47px;
    background-color: rgba(255, 255, 255, 0.1) !important;
    border: 1px solid rgba(255, 255, 255, 0.4) !important;
    box-sizing: border-box;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px !important;
    font-size: 12px !important;

    > svg {
        margin-right: 10px;
    }
}

.section-container {
    display: flex;
    justify-content: center;
    padding-top: 1.5rem;
    background-color: #f2f2f2;
}

.table-wrapper {
    height: 30vh;
    width: 65vw !important;
    overflow: auto;
    .table {
        th {
            padding-bottom: 1rem !important;
        }
        tbody {
            background-color: #fff !important;
            tr {
                box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
                border-radius: 4px;
            }
            td {
                font-weight: 800;
                font-size: 12px;
                vertical-align: middle;
            }
            td:first-child {
                padding-left: 1rem !important;
                width: 25vw;
            }
            td:last-child {
                padding-right: 1.25rem !important;
                display: flex;
                justify-content: center;
                svg {
                    color: #439CD1
                }
            }
        }
    }
}

.spinner {
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    margin-bottom: 2rem;
    > svg {
        width: 4rem;
        height: 4rem;
    }
}
