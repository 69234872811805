.export-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    gap: 15px;
    position: absolute;
    width: 521px;
    height: 50px;
    z-index: 1;
}

.export-btn {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14.1176px !important;
    gap: 7.06px;
    width: 250px;
    height: 48px;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px !important;
    font-weight: 400 !important;
    font-size: 12px !important;
}

.export-svg-btn {
    background-color: #FFFFFF !important;
    color: #474747 !important;
}

.export-pdf-btn {
    background-color: #41A8F9 !important;
    color: #FFFFFF !important;
}