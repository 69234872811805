.add-label-btn {
    padding: 0.75rem !important;
    color: #474747 !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    width: 250px;
    height: 48px;
    background-color: #FFFFFF !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px !important;
    margin: 0px 15px;
}

.import-portfolio-btn {
    padding: 0.75rem !important;
    color: #FFFFFF !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    width: 250px;
    height: 48px;
    background-color: #41A8F9 !important;
    border: 1px solid #41A8F9 !important;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px !important;
    margin: 0px 15px;
}

.import-portfolio-btn:hover {
	color: #41A8F9 !important;
	background-color: #FFFFFF !important;
}

.btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    height: 48px;
    top: 27px;
}

.validation-btn {
    position: absolute;
    width: 315px;
    height: 48px;
    background-color: #41A8F9 !important;
    border-color: #41A8F9 !important;
    box-shadow: 2px 2px 1px rgb(0 0 0 / 5%);
    border-radius: 80px !important;
    bottom: 2%;
}

.add-tuple-btn {
    position: absolute;
    width: 315px;
    height: 52px;
    background-color: #FFFFFF !important;
    border: 1px solid #E3E3E3 !important;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 8%), 0px 1px 4px rgb(0 0 0 / 6%);
    border-radius: 4px !important;
    bottom: 10%;
    color: #3C3C3B !important;
    z-index: 1;
}

.validate-geoeditor-btn {
	padding: 0.75rem !important;
	color: #41A8F9 !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	width: 300px;
	height: 48px;
	background-color: #FFFFFF !important;
	border: 1px solid #41A8F9 !important;
	box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
	border-radius: 4px !important;
	margin: 0px 15px;
}

.validate-geoeditor-btn:hover {
	color: #FFFFFF !important;
	background-color: #41A8F9 !important;
}