.content-container {
    display: flex;
    flex-direction: column;
    height: 70vh;
    justify-content: center;
    align-items: center;
    background: #183B68;
    color: white;
}

.full-content-container {
    height: calc(100vh - 68px);
}

.geographic-content-container {
    height: 40vh;
    .content-container-input {
        margin-top: 60px;
    }
}

.content-container-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-style {
    font-weight: 900;
    font-size: 36px;
    color: #FFFFFF;
    margin: 16px 0px;
}

.notice-style {
    color: #B6B6B6;
    margin: 16px 0px;
}

.content-container-input {
    margin-top: 129px;
    .input-group-text {
        background-color:  #F5F6F6;
        color: #272727;
    }
    .form-control {
        background-color:  #F5F6F6;
        color: #43454A;
    }
}

.settings-content-container {
    height: 30vh;
}