.library-btn {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px !important;
    grid-gap: 11px;
    gap: 11px;
    position: absolute;
    width: 120px;
    height: 99px;
    bottom: 1%;
    background: #FFFFFF !important;
    border: 2px solid #41A8F9 !important;
    box-shadow: 2px 2px 1px rgb(0 0 0 / 5%);
    border-radius: 4px !important;
    margin-left: 1rem;
    color: #41A8F9 !important;
    font-weight: 900 !important;
    font-size: 12px !important;
    white-space: pre-wrap !important;
}

.library-btn-icon {
    width: 32px;
    height: 32px;
}

.modal-btn {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 6px 6px 8px !important;
    position: absolute;
    left: 2rem;
    right: 2rem;
    background: #41A8F9;
    border-radius: 30px !important;
    color: #FFFFFF !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    bottom: 2%;
}

.add-filter-btn {
    margin-bottom: 2rem;
    bottom: 5% !important;
    margin-left: 0.75rem;
    margin-right: 0 !important;
    background-color: #FFFFFF !important;
    border: 1px solid #E3E3E3 !important;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 8%), 0px 1px 4px rgb(0 0 0 / 6%);
    border-radius: 4px !important;
    color: #3C3C3B !important;
}