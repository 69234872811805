.add-object-btn {
	padding: 0.75rem !important;
	color: #FFFFFF !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	width: 300px;
	height: 48px;
	background-color: #41A8F9 !important;
	border: 1px solid #41A8F9 !important;
	box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
	border-radius: 4px !important;
	margin: 0px 15px;
}

.add-object-btn:hover {
	color: #41A8F9 !important;
	background-color: #FFFFFF !important;
}

.validate-object-btn {
	padding: 0.75rem !important;
	color: #FFFFFF !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	bottom: 27px;
	width: 300px;
	height: 48px;
	background-color: #41A8F9 !important;
	border: 1px solid #41A8F9 !important;
	box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
	border-radius: 4px !important;
	margin: 0px 15px;
}

.validate-object-btn:hover, .cancel-object-btn:hover {
	color: #41A8F9 !important;
	background-color: #FFFFFF !important;
}

.switch-geoeditor-btn {
	padding: 0.75rem !important;
	color: #FFFFFF !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	width: 300px;
	height: 48px;
	background-color: #41A8F9 !important;
	border: 1px solid #41A8F9 !important;
	box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
	border-radius: 4px !important;
	margin: 0px 15px;
}

.switch-geoeditor-btn:hover {
	color: #41A8F9 !important;
	background-color: #FFFFFF !important;
}

.switch-geoeditorobject-btn {
	position: absolute;
	padding: 0.75rem !important;
	color: #41A8F9 !important;
	font-weight: 400 !important;
	font-size: 12px !important;
	width: 300px;
	height: 48px;
	background-color: #FFFFFF !important;
	border: 1px solid #41A8F9 !important;
	box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
	border-radius: 4px !important;
	margin: 0px 15px;
	top: 27px;
}

.switch-geoeditorobject-btn:hover {
	color: #FFFFFF !important;
	background-color: #41A8F9 !important;
}

.btn-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	position: absolute;
	height: 48px;
	top: 27px;
}

.modification-btn-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	position: absolute;
	height: 48px;
	bottom: 27px;
	left: 10%;
}