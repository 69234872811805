.tooltip-btn {
    .tooltip-inner {
        color: #000;
        background-color: #fff;
    }
}

.badge-style {
    padding: 0.2rem !important;
    font-size: .6rem !important;
    color: #fff;
}