.stepper-style {
    .MuiStepLabel-label {
        color: #FFFFFF !important;
        font-size: 24px;
        font-weight: 600;
    }
    .MuiSvgIcon-root {
        color: #9D327A !important;

    }
    .MuiTypography-root {
        font-size: 16px;
        color: #F4F4F4 !important;
    }
    .MuiStepContent-root {
        border-left: 1px solid #9D327A !important;
    }
    .MuiStepConnector-line {
        border-color: #9D327A !important;
    }
    .Mui-completed {
        opacity: 1 !important;
    }
}

.summary-stepper {
    align-items: flex-start;
    padding: 24px 0px;
    width: 729px;
    height: 411px;
}

.top-bar-stepper {
    width: 770px;
    height: 64.27px;
    padding-top: 5px;
    .MuiStepLabel-label {
        font-size: 10px !important;
        margin-top: 8px !important;
    }
}

.step-style {
    opacity: 0.2 !important;
}