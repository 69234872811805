.sections-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 24px;
}

.sections-details {
  height: 60vh;
  overflow: auto;
  .list-group-item {
    margin-bottom: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 16px 0px;
  }
  .col {
    font-size: 12px;
  }
  .col:first-child {
    font-weight: 400;
    color: #5c5d61;
  }
  .col:last-child {
    color: #2c2c2c;
    font-weight: 700;
    display: flex;
    flex-direction: row-reverse;
  }
}

.update-perimeter-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.update-perimeter-btn {
  width: 315px;
  height: 48px;
  background-color: white !important;
  color: #272727 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 4px !important;
  margin: 32px 0px;
  padding: 0 !important;
  position: relative;

  svg {
    margin-right: 0.5rem;
  }
}

.button-toggle-panel {
  position: absolute;
  right: -40px;
  z-index: 1;
  bottom: 40px;
  background: #4d4f53;
}