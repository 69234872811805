.input-label {
    font-size: 12px;
    color: #E1E1E1;
}

.input-field {
    background: #F5F6F6;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
}

.input-error {
    .input-label {
        color: #FF5737;
    }
    .invalid-feedback {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #FF5737;
    }
}

.label-form-select {
    border: 1px solid #BFBFC2;
    color: #0088ce;
}

.label-form-select:focus {
    border: 1px solid #4fc3ff !important;
}

.toggle-button {
    background-color: transparent !important;
    color: #333 !important;
    border: none !important;
    padding: 0 1rem 0 0 !important;
    bottom: 0;
    position: absolute;
    right: 0;
    top: 0;
    svg {
        font-size: 22px;
    }
  }