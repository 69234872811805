.map-wrapper {
    flex: 1;
  }

.portfolio {
  height: calc(100vh - 163px);
}
  
.mapboxgl-map {
  background: #E5E5E5;
}