.title {
	font-weight: 400 !important;
	font-size: 12px;
	color: #868686;
}

.display-icons { 
    position: static;
    opacity: 0.5;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 24px;
    width: 24px;
    height: 24px;
}

.display-icons-active {
    color: #439CD1;
}

.card-synoptic {
    width: 18.5rem; 
    height: 12rem;
    box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
    position: relative;
	.card-text {
	    font-size: 0.8rem;
	}
}

.card-tools-btn {
    border-color: transparent !important;
    padding: 0 !important;
    color: #868686 !important;
    height: 1rem;
}

.card-tools-btn > svg {
    vertical-align: top !important;
}

.table-synoptic {
	th {
		font-weight: 400 !important;
		padding-bottom: 2rem !important;
		font-size: 12px;
		color: #868686;
	}
	td {
		padding-bottom: 0.5rem !important;
		padding-top: 0.5rem !important;
        font-size: 13px;
	}
	background-color: transparent !important;
	th:first-child {
		padding-left: 0 !important;
	}
	td:first-child {
		padding-left: 0 !important;
	}
    td:last-child {
        padding-right: 6.5rem !important;
    }
    td:last-child > svg {
        color: #868686;
    }
}

.btn-add-synoptic {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px !important;
    position: absolute;
    width: 449px;
    height: 56px;
    bottom: 5%;
    background-color: #439CD1 !important;
    border-color: #439CD1 !important;
    border-radius: 80px !important;
}

.btn-add-synoptic-icon {
    margin: 0px 16px;
}

input{
    &::-webkit-inner-spin-button{
      opacity: 1; 
    } 
}

.card-container {
    overflow-y: auto;
    max-height: 56vh;
}

.tab-close-btn {
    display: flex;
    align-items: center;
    color: #939393;
    font-size: 1.3rem;
}

.tab-active {
    background-color: #606164;
}

.last-modification {
    font-weight: light; 
    color: #439CD1;
}

.card-bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 1.5rem;

    .card-text {
        position: static;
        width: 110px;
        height: 24px;
        font-weight: light;
        color: #AFAFAF;
    }
    .btn {
        border-radius: 1.4375rem !important;
        position: absolute !important;
        font-size: 12px !important;
        right: 1.5rem;
    }
}

.order-dropdown {
    display:flex;
    font-size: 12px;
    .dropdown-menu {
        transform: translate3d(0px, 5px, 0px) !important;
        font-size: 12px;
    }
}

.tab-object-header {
    position: absolute;
    background: #43454a;
    color: white;
    height: 98px;
    width: 100%;
    display: flex;
    align-items: center;
}