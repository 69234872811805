.geoeditor-tools {
  position: absolute;
  width: 100%;
  height: 3.125rem;
  
  .button-group {
    border-radius: 0px !important;
    padding: 10px;
    height: 48px;
    width: 100%;
    background-color: #404040 !important;

    .icon-button {
      background-color: #404040;
      border-radius: 8px;
      height: 2rem;
      width: 2rem;

      &.selected {
        background-color: #727272;
      }
    }
  }

  .divider {
    border-color: unset;
    margin-left: .5rem !important;
  }
}

#deckgl-wrapper {
  &.draw {
    cursor: url(../../../assets/icons/draw_mouse_dark.svg) 0 0, pointer;
  }

  &.select {
    cursor: url(../../../assets/icons/select_mouse_dark.svg) 0 0, pointer;
  }

  &.deletePoint {
    cursor: url(../../../assets/icons/bin_dark.svg) 0 0, pointer;
  }

  &.rotate {
    cursor: url(../../../assets/icons/rotate_dark.svg) 0 0, pointer;
  }
}