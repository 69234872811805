.filter-btn {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px !important;
    grid-gap: 16px;
    gap: 16px;
    background-color: #FFF !important;
    width: 35px;
    height: 45px;
    border: none !important;
}

.filters-modal {
    z-index: 1056 !important;
    .modal-dialog {
        top: 15px;
        max-width: 45vw;
    }
    .modal-header {
        svg {
            width: 24px;
            height: 24px;
            color: #272727;
        }
        .modal-title {
            font-size: 24px;
            color: #272727;
        }
    }
    .modal-body {
        height: 60vh;
        overflow-y: auto;
    }
    .modal-footer {
        padding: 0;
        height: 21vh;
    }
}

.add-filter-modal {
    .modal-body {
        height: 55vh !important;
    }
    .modal-footer {
        height: 26vh !important;
    }
}

.library-form {
    .form-label {
        font-weight: 500;
        font-size: 16px;
        color: #272727;
        margin: 2vh 0;
    }
    .form-control {
        background: #FFFFFF;
        border: 1px solid #BFBFC2;
        border-radius: 4px;
    }
    .form-control::placeholder {
        font-weight: 400;
        font-size: 12px;
        color: #D5D5D5;
    }
}

.add-filter-container {
    width: 38vw;
    margin-left: 1rem;
    align-self: start;
}

.close-btn {
    margin: 2vh 0;
}

.phase-text {
    font-weight: 500;
    font-size: 16px;
    color: #272727;
    margin: 2vh 0;
}

.phase-sub-text {
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #8A8A8A;
    margin-right: 0;
    padding: 0.25rem 0.5rem;
}

.phase-close-btn {
    margin-right: 0.5rem;
    margin-top: 0.75rem;
}

.phase-container {
    width: 100%;
    margin-right: 0 !important;
    padding: 0 0.5rem !important;
}