.panel-form {
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    width: 100%;
    height: 54vh;
    overflow-y: auto;
    .form-control {
        background-color: #FFFFFF;
        border: 1px solid #BFBFC2;
        box-sizing: border-box;
        border-radius: 4px;
    }
    .input-label {
        color: #8A8A8A;
    }
}

.divider {
    border-bottom: 1px solid #d7d7d7;
    margin-top: 0.5rem;
}

.area-text {
    font-weight: 800;
    font-size: 12px;
    color: #3C3C3B;
}