.popup-wrapper {
    width: 191px;
    background-color: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.06);
    border-radius: 4px;

    .mapboxgl-popup-content {
        padding: 10px !important;
        cursor: default;
    }
}

.popup-items {
    font-size: 12px;
    color: #43454A;
    padding: 12px;
}

.popup-items:hover {
    background-color: #F0F0F0;
}