.import-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 37px;
    width: 422px;
    height: 305px;
    border: 1px dashed rgba(255, 255, 255, 0.5);
}

.import-box:hover {
    cursor: pointer;
}

.import-icon-style {
    width: 50px;
    height: 50px;
    color: #FFFFFF;
}

.import-text {
    width: 326px;
    height: 72px;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    > strong {
        color: #FFFFFF;
    }
}

.steps-content {
    margin-top: 0px !important;
    margin-bottom: 129px;
}

.attributs-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;
    background-color: #f2f2f2;
    .input-group-text {
        color: #272727;
    }
    .form-control {
        background: #FFFFFF;
        border: 1px solid rgba(24, 59, 104, 0.1);
        border-radius: 4px;
        width: 540px;
        color: #9D327A;
    }
    .form-control::placeholder {
        color: rgba(189, 45, 95, 0.3);
    }
}

.add-attribut-btn {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 7.06px;
    height: 47px;
    background-color: #9D327A !important;
    border-color: #9D327A !important;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
    border-radius: 4px !important;
}

.attributs-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 105px;
    height: 48vh;
    background-color: #f2f2f2;
}

.example-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0px;
    width: 65vw;
    height: 30vh;
    overflow-y: auto;
}

.label-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 12px;
    width: 30vw;
    height: fit-content;
    min-height: 25vh;
    background-color: #FBFBFB;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    white-space: pre-wrap;
}

.attributs-example-container {
    padding-left: 5rem;
    ul {
        margin-block-end: 0;
    }
    .MuiTimeline-root {
        padding: 0;
      }
    
      .MuiTimelineContent-root {
        padding-right: 2px;
        padding-left: 25px;
        align-self: center;
      }
    
      .MuiTimelineItem-root {
          min-height: 42px !important;
        &::before {
          content: none;
        }
      }
    
      .MuiTimelineDot-root {
        position: absolute;
        margin: 0;
        top: 20px;
        left: 0.5px;
        background-color: #BED4EF;
        border-width: 1px;
        box-shadow: none;
      }
      
      .MuiTimelineConnector-root {
        position: absolute;
        display: block;
        left: 5px;
        height: 100%;
        top: 32px;
        background-color: #BED4EF;
        width: 1px;
      }
}

.attribut-btn {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px !important;
    gap: 10px;
    height: 30px;
    background-color: #9D327A !important;
    border-color: #9D327A !important;
    border-radius: 12px !important;
    > svg {
        width: 1.5rem;
        height: 1.5rem;
    }
}

.ferro-localization-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0px;
    gap: 24px;
    width: 50vw;
    .form-control {
        background: #FFFFFF;
        border: 1px solid rgba(24, 59, 104, 0.1);
        border-radius: 4px;
        width: 540px;
        color: #9D327A;
    }
    .form-control::placeholder {
        color: rgba(189, 45, 95, 0.3);
    }
}

.ferro-localization-fields {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    padding: 0px;
    gap: 44px;
    width: 50vw;
    height: 56px;
    .MuiChip-root {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        gap: 12px;
        background-color: #9D327A;
        border-radius: 8px;
        color: #FFFFFF;
    }
}

.arrow-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.elipse-style {
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    background: #FFFFFF;
    border: 2px solid #41A8F9;
    border-radius: 4px;
    margin-right: -1px;
}

.line-style {
    width: 145px;
    height: 0px;
    border: 2px solid #41A8F9;
}

.arrow-style {
    color: #41A8F9;
    margin-left: -7px;
}

.imported-rows-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 24px;
    width: 736px;
    height: 454px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 12px;
}

.check-icon {
    width: 96px;
    height: 96px;
    color: #8BC259;
    border-radius: 80px;
}

.imported-rows-text {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 800;
    font-size: 38px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.not-positioned-labels {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #F1F1F1;
}

.pin-icon-container {
    width: 96px;
    height: 96px;
    border-radius: 80px;
    background-color: #8BC259;
    .MuiSvgIcon-root {
        width: 96px !important;
        height: 90px !important;
        color: #122c4e;
    }
}

.import-notice-card {
    position: absolute !important;
    left: 2%;
    bottom: 2%;
    background: #FFFFFF;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    .card-body {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: #183B68;
        ul {
            margin-top: 1rem;
            margin-bottom: 0;
        }
        li {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 1rem;
        }
        svg {
            height: 2em;
            width: 2em;
        }
    }
}