.top-bar {
    display: flex;
    align-items: center;
    width: 100%;
    height: 68px;
    background-color: #183b68;
}

.top-bar-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    color: #fff;
    align-items: center;
}

.btn-close {
    margin-right: 24px;
}

.previous-step {
    display: flex;
    flex-direction: row;
    align-items: center;
    left: 0;
    top: 0;
}

.previous-step-text {
    position: static;
}

.previous-step-icon {
    margin: 0 24px;
}