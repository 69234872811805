.start-btn {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px !important;
    position: absolute;
    width: 398px;
    height: 58px;
    bottom: 5%;
    background-color: #FFFFFF !important;
    border-color: #FFFFFF !important;
    border-radius: 30px !important;
    color: #183B68 !important;
}

.continue-btn {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 6px 6px 8px !important;
    position: absolute;
    width: 398px;
    height: 58px;
    bottom: 5%;
    background-color: #183B68 !important;
    border-color: #183B68 !important;
    border-radius: 30px !important;
}