.map-tool-bar {
  position: absolute;
  right: 0;
  top: 3rem;
  z-index: 1;
  background-color: #404040 !important;
  transition: right 150ms ease-in-out;
}

.map-tool-bar-extended {
  position: absolute;
  right: 300px;
  top: 3rem;
  z-index: 1;
  background-color: #404040 !important;
  transition: right 150ms ease-in-out;
}

.panel-tool-bar {
  position: absolute;
  right: 0;
  width: 0;
  z-index: 1;
  background-color: white;
  height: calc(100vh - 163px);
  border: 1px solid #d7d7d7;
  border-right: none;
  transition: width 150ms ease-in-out;
  font-size: 12px !important;
}

.panel-tool-bar-extended {
  width: 300px;
  position: absolute;
  right: 0;
  z-index: 1;
  background-color: white;
  height: calc(100vh - 163px);
  border: 1px solid #d7d7d7;
  border-right: none;
  transition: width 150ms ease-in-out;
  overflow: auto;
}

.customHoverFocus {
  &:focus {
    outline: none !important;
  }
}
.fullscreen-control {
  position: relative !important;
  padding-bottom: 0.2rem;
  .mapboxgl-ctrl-group {
    background: transparent;
    box-shadow: none !important;
    button + button {
      border-top: none;
    }
    > button span {
      color: white;
    }
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-fullscreen .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 8V0H10L13.29 3.29L3.29 13.29L0 10V18H8L4.71 14.71L14.71 4.71L18 8Z' fill='white'/%3E%3C/svg%3E%0A") !important;
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-shrink .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 8V0H10L13.29 3.29L3.29 13.29L0 10V18H8L4.71 14.71L14.71 4.71L18 8Z' fill='white'/%3E%3C/svg%3E%0A") !important;
  }
}

.navigation-control {
  position: relative !important;
  padding-bottom: 0.2rem;
  .mapboxgl-ctrl-group {
    background: transparent;
    box-shadow: none !important;
    button + button {
      border-top: none;
    }
    > button span {
      color: white;
    }
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
    background-image: url(data:image/svg+xml;charset=utf8,<svg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27>%0A%20%20<path%20style%3D%27fill%3A%23FFFFFF%3B%27%20d%3D%27M%2010%206%20C%209.446%206%209%206.4459904%209%207%20L%209%209%20L%207%209%20C%206.446%209%206%209.446%206%2010%20C%206%2010.554%206.446%2011%207%2011%20L%209%2011%20L%209%2013%20C%209%2013.55401%209.446%2014%2010%2014%20C%2010.554%2014%2011%2013.55401%2011%2013%20L%2011%2011%20L%2013%2011%20C%2013.554%2011%2014%2010.554%2014%2010%20C%2014%209.446%2013.554%209%2013%209%20L%2011%209%20L%2011%207%20C%2011%206.4459904%2010.554%206%2010%206%20z%27%20%2F>%0A<%2Fsvg>%0A) !important;
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
    background-image: url(data:image/svg+xml;charset=utf8,<svg%20viewBox%3D%270%200%2020%2020%27%20xmlns%3D%27http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%27>%0A%20%20<path%20style%3D%27fill%3A%23FFFFFF%3B%27%20d%3D%27m%207%2C9%20c%20-0.554%2C0%20-1%2C0.446%20-1%2C1%200%2C0.554%200.446%2C1%201%2C1%20l%206%2C0%20c%200.554%2C0%201%2C-0.446%201%2C-1%200%2C-0.554%20-0.446%2C-1%20-1%2C-1%20z%27%20%2F>%0A<%2Fsvg>%0A) !important;
  }
  .mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%2341A8F9'%3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%23ffffff'/%3E%3C/svg%3E") !important;
  }
}

.infra-objetcs-loader-extended {
  position: absolute;
  background: #f0f8ffb8;
  height: 100%;
  width: 300px;
  right: 0;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: width 150ms ease-in-out;
}

.infra-objetcs-loader {
  position: absolute;
  background: #f0f8ffb8;
  height: 100%;
  width: 0;
  right: 0;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: width 150ms ease-in-out;
}

.relaunch-btn {
  width: 215px;
  height: 40px;
  background-color: white !important;
  color: #272727 !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-sizing: border-box;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.05);
  border-radius: 4px !important;
  margin: 10px 0px;
  padding: 0 !important;
}

.infra-objects-accordion {
  .accordion-button {
    height: 14px;
    font-weight: 900;
    font-size: 12px;
    color: #3C3C3B;
  }
}

