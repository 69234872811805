.sketch-picker {
    padding: 0 !important;
    box-shadow: none !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100% !important;
    div {
        border-radius: 4px;
    }
    .flexbox-fix {
        padding: 3px 0;
    }
    .flexbox-fix:nth-of-type(3) {
        div {
            display: flex;
            flex-direction: column-reverse;
            padding-bottom: 0.25rem;
        }
    }
    .flexbox-fix:last-child {
        padding: 10px 0px 0px 20px !important;
    }
    .hue-horizontal:last-child {
        div {
            border-radius: 4px !important;
            width: 8px !important;
        }
    }
    .saturation-white:last-child {
        div:last-child {
            width: 10px !important;
            height: 10px !important;
        }
    }
    input {
        border-radius: 4px;
    }
    label {
        display: flex !important;
        font-weight: 900 !important;
        font-size: 12px !important;
        margin-bottom: 0.25rem !important;
    }
}

.color-picker-wrapper {
    padding: 16px;
    position: absolute;
    width: 300px;
    top: 0px;
    left: 0px;
    background-color: #FFFFFF;
    box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.08), 0px 1px 5px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
}

.color-picker-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    margin-bottom: 14px;
    position: static;
    font-weight: 500;
    font-size: 16px;
    color: #272727;
    svg {
        height: 1.5em;
        width: 1.5em;
    }
}