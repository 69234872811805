.custom-pagination {
    justify-content: center;
    padding-top: 0.5rem; 
    position: absolute;
    bottom: 13%; 
    
    .page-item {
        padding: 0 1.25rem;
        font-weight: 500;
    }

    .visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
        width: 1px!important;
        height: 0px!important;
        overflow: hidden!important;
    }

    .page-item.active .page-link::after {
        content: none;
    }
}